import React, { useEffect, useState } from 'react'
import GetDataAscendingOrder from '../../../service/sortService';
import service from '../../../service/tableHideService';
import '../../Demo/Demo1/Body.css';
import { leaderBoardData } from '../../../constants/data/leaderBoardJson';
import Even from '../../zigZag/Even';
const languages = require('../../../constants/json/Language.json');
const sortedLanguages = GetDataAscendingOrder(languages, "language")
const tableData = require('../../../constants/json/Table.json');

const Body = () => {

    const [tableDatas, setTableDatas] = useState({})
    const [ispresent, setIsPresent] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 14]);
    const [tabId, setTabId] = useState("hindi");

    useEffect(() => {
        let data = filterdData(tabId);
        setTableDatas(data)

    },[])



    const handleTabClick = (id) => {
        setTabId(id)
        let data = filterdData(id)
        let presentList = service.tableHideService(data);
        setIsPresent(presentList);
        setTableDatas(data)
    }

    const filterdData = (language) => {
        let data = tableData?.find((item) => {
            return item?.langName.toLowerCase() === language.toLowerCase()
        })
        return data;
    }


    return (

        <>
            <div >
                <>
                    {
                        leaderBoardData?.map((data) => {
                            return (
                                <div key={data.id}>
                                    <Even data={data} />
                                </div>
                            )
                        })
                    }
                </>
                <div className="w-full rounded-xl p-[30px] 2xl:p-[50px] flex flex-col gap-y-10 bg-[#ECF2FE] mt-10">
                    <div className="w-full rounded-xl flex flex-col gap-y-5 bg-[#ECF2FE] " >
                        <div className="w-full flex items-center gap-x-3 text-[#363636] text-base font-normal	not-italic tabAlign">
                            {
                                sortedLanguages?.map(item => {
                                    return (
                                        <button key={item?.language} className={`w-[100px]  ${tabId.toLowerCase() === item.language.toLowerCase() && 'text-white bg-[#CE5347]  '} text-center py-1 px-1 outline-none rounded-lg text-[15px] font-semibold`}
                                            onClick={() => handleTabClick(item.language)}>{item.language}</button>
                                    )
                                })
                            }
                        </div>

                        {ispresent.length === 0 ? <div className='flex items-center justify-center w-full h-10 bg-white rounded-lg'>No data</div> :
                            <div className="w-full  rounded-lg bg-[#FFF] tableAlignment ">
                                <table className="w-full ">
                                    <thead className="w-full text-[#363636] text-[16px] font-[200px] not-italic bg-gray-100 tableAlignment ">
                                        <tr>
                                            {ispresent.includes(1) ? <th >{tableDatas?.langName} </th> : ""}
                                            {ispresent.includes(2) ? <th >  Common Voice </th> : ""}
                                            {ispresent.includes(3) ? <th > Fleurs </th> : ""}
                                            {ispresent.includes(4) ? <th > Gramvaani </th> : ""}
                                            {ispresent.includes(5) ? <th > IITM </th> : ""}
                                            {ispresent.includes(6) ? <th > IndicTTS </th> : ""}
                                            {ispresent.includes(7) ? <th > MUCS </th> : ""}
                                            {ispresent.includes(8) ? <th > ULCA </th> : ""}
                                            {ispresent.includes(9) ? <th > SPRING_Test </th> : ""}
                                            {ispresent.includes(10) ? <th > Kathbath </th> : ""}
                                            {ispresent.includes(11) ? <th >  Kathbath hard </th> : ""}
                                            {ispresent.includes(12) ? <th >    IIIT-H test </th> : ""}
                                            {ispresent.includes(13) ? <th >  Babel </th> : ""}
                                            {ispresent.includes(14) ? <th >  Average </th> : ""}
                                        </tr>
                                    </thead>
                                    <tbody className="text-[#363636] font-normal text-sm not-italic text-center ">
                                        {tableDatas?.data?.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    {ispresent.includes(1) ? <td >{item.LangCode} </td> : ""}
                                                    {ispresent.includes(2) ? <td > {item.CommonVoice} </td> : ""}
                                                    {ispresent.includes(3) ? <td > {item.Fleurs} </td> : ""}
                                                    {ispresent.includes(4) ? <td > {item.Gramvaani} </td> : ""}
                                                    {ispresent.includes(5) ? <td > {item.IITM} </td> : ""}
                                                    {ispresent.includes(6) ? <td > {item.IndicTTS} </td> : ""}
                                                    {ispresent.includes(7) ? <td > {item.MUCS} </td> : ""}
                                                    {ispresent.includes(8) ? <td > {item.ULCA} </td> : ""}
                                                    {ispresent.includes(9) ? <td > {item.SPRING_Test} </td> : ""}
                                                    {ispresent.includes(10) ? <td > {item.Kathbath} </td> : ""}
                                                    {ispresent.includes(11) ? <td > {item.kathbathHard} </td> : ""}
                                                    {ispresent.includes(12) ? <td >  {item.IIT_H_test} </td> : ""}
                                                    {ispresent.includes(13) ? <td >  {item.Babel} </td> : ""}
                                                    {ispresent.includes(14) ? <td >  {item.Average} </td> : ""}
                                                </tr>
                                            )
                                        })

                                        }



                                    </tbody>
                                </table>
                            </div>
                        }

                    </div>


                </div>
            </div>
        </>

    )
}

export default Body