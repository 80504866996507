import React from 'react'
import FAQLIst from '../../../Shared/FAQLIst'

const Faq = () => {
  const faqData = require('../../../../constants/json/FAQ.json')
  return (
    <div className='w-full flex gap-3 flex-col'>
      {
        faqData?.map((item) => {
          return (
            <div key={item?.id}>
              <FAQLIst item={item} />
            </div>
          )
        })
      }
    </div>
  )
}

export default Faq