import { ImageUrl } from "../url/ImagePath";


export const leaderBoardData = [

    {
        id: 1,
        header: (
            <span>Leader
                {""} <span className="text-[#CB8461]">Board</span></span>
        ),
        description: `We test the models across a variety of test sets for each Indian language. The performance is presented in terms of Word Error Rates without use of Language Models( lower the better ).
        We hope this leaderboard will encourage people to submit better models that improve the existing state-of-art.`,
        imgUrl: ImageUrl.langFoundationImg,
        table: '',
        gitHubLink: ''
    },

]
