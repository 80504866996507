import React from 'react'
import Nav from '../component/Demo/Demo2/Nav'
import Footer from '../component/Demo/Footer'
import Header from '../component/Demo/Demo2/Header'
import Body from '../component/Demo/Demo2/Body'


const Demo2 = () => {
  return (
    <div>
      <Header />
      <Body />
    </div>
  )
}

export default Demo2