import React from 'react'
import Body from '../../Demo3/Body'

const LeaderBoard = () => {
  return (
    <div className='w-full'>
        <Body/>
    </div>
  )
}

export default LeaderBoard