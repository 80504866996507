import React from 'react'
import { modelJson } from '../../../../constants/data/modelPageJson';
import Even from '../../../zigZag/Even';
import Odd from '../../../zigZag/Odd';



const Model = () => {

    return (
        <>
            {modelJson.map((data, index) => {
                if (index % 2 === 0) {
                    return (
                        <div key={data.id}>
                            <Even data={data} />
                        </div>
                    )
                }
                else {
                    return (
                        <div key={data.id}>
                            <Odd data={data} />
                        </div>
                    )
                }
            })}
        </>
    )
}

export default Model