
const tableHideService=(data)=>{
    var presentColumns=[]
    data.data.forEach(item => {
        if(item.LangCode!==""&&(!presentColumns.includes(1))){
            presentColumns.push(1);
        }
        if(item.CommonVoice!==""&&(!presentColumns.includes(2))){
            presentColumns.push(2);
        }
        if(item.Fleurs!==""&&(!presentColumns.includes(3))){
            presentColumns.push(3);
        }
        if(item.Gramvaani!==""&&(!presentColumns.includes(4))){
            presentColumns.push(4);
        }
        if(item.IITM!==""&&(!presentColumns.includes(5))){
            presentColumns.push(5);
        }
        if(item.IndicTTS!==""&&(!presentColumns.includes(6))){
            presentColumns.push(6);
        }
        if(item.MUCS!==""&&(!presentColumns.includes(7))){
            presentColumns.push(7);
        }
        if(item.ULCA!==""&&(!presentColumns.includes(8))){
            presentColumns.push(8);
        }
        if(item.SPRING_Test!==""&&(!presentColumns.includes(9))){
            presentColumns.push(9);
        }
        if(item.Kathbath!==""&&(!presentColumns.includes(10))){
            presentColumns.push(10);
        }
        if(item.kathbathHard!==""&&(!presentColumns.includes(11))){
            presentColumns.push(11);
        }
        if(item.IIIT_H_test!==""&&(!presentColumns.includes(12))){
            presentColumns.push(12);
        }
        if(item.Babel!==""&&(!presentColumns.includes(13))){
            presentColumns.push(13);
        }
        if(item.Average!==""&&(!presentColumns.includes(14))){
            presentColumns.push(14);
        }
    });
    if(presentColumns.length===1){
        return []
    }

    return presentColumns

}
export  default {tableHideService};
