import React from "react";
import GetDataAscendingOrder from "../../../../service/sortService";
import TableLink from "../../../TableLink";

const tableData = require("../../../../constants/dataset/downloadData/table1.json");
const sorteddata = GetDataAscendingOrder(tableData, "language");

export const FirstTable = () => {
  return (
    <div className="w-full rounded-2xl bg-[#FFF] tableAlignment">
      <table className="w-full ">
        <thead className="w-full text-[#363636] text-[13px] font-normal not-italic bg-gray-100 tableAlignment ">
          <tr>
            <th>
              <div className="flex items-center justify-center gap-x-2 text-[16px]">
                <div> Languages</div>
              </div>
            </th>   
            <th>
              <div className="flex items-center justify-center gap-x-2 text-[16px]">
                <div>Data_R1</div>
              </div>
            </th>
            <th>
              <div className="flex items-center justify-center gap-x-2 text-[16px]">
                <div>Data_R2</div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="text-[#363636] font-normal text-[13px] not-italic text-center ">
          {sorteddata.map((item) => {
            return (
              <tr key={item.language} className=" cursor-pointer ">
                <td className="text-[#030F30] pointer-events-none text-[20px]">
                  {item.language}
                </td>
                <td>
                  <TableLink name={item.name} link={item.data} />
                </td>
                <td>
                  <TableLink name={item.name} link={item.data2} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
