import { NavLink } from "react-router-dom";
export default function ProjectList(props) {
  return (
    <div className="w-full px-5 py-4 bg-[#F8F8F8] rounded-lg ">
      <div className="flex flex-row justify-between">
        <p className="text-[#CE5347] text-[14px] font-semibold">
          {props.author}
        </p>
        <p className="text-[#CE5347] text-[14px] font-semibold">
          {props.years}{" "}
        </p>
      </div>

      <p className="text-[14px] font-normal">
        <NavLink
          className="hover:text-blue-700"
          target="_blank"
          to={"https://bhashini.gov.in/"}
        >
          {props.link ?? ""}
        </NavLink>{" "}
        <br />
        {props.topic}
      </p>
    </div>
  );
}
