import React from "react";
import { useState, useEffect } from "react";
import EmployeesDetailsCard from "./EmployeesDetailsCard";

export default function EmployeesContainer() {
  const [clicked, setClicked] = useState("");
  const [jsonData, setJsonData] = useState("");

  let data = require("../../constants/json/emloyees.json");

  const handleClick = (event) => {
    setClicked(event.target.dataset.user);
    setJsonData(event.target.dataset.user);
  };

  useEffect(() => {
    setClicked(" Ph.D Students");
    setJsonData(" Ph.D Students");
  }, []);

  return (
    <div>
      <div className="px-4 md:px-16 pt-12">
        <div className="border-2 border-gray-100 rounded-[20px] w-full md:flex">
          <div className="w-full md:w-1/3 border-r-2 pt-3">
            {data &&
              Object.keys(data).map((category, index) => (
                <div key={index} className="border-b-2">
                  <div
                    onClick={handleClick}
                    data-user={category}
                    className={`${
                      clicked === category
                        ? "pl-7 not-italic border-l-2 border-[#1875F0]  font-[Poppins] font-bold text-[#5196F3] 2xl:text-[18px]  text-[14px] py-5"
                        : "pl-7  not-italic font-[Poppins] 2xl:text-[18px] text-[14px] py-5 font-medium text-[#999999]"
                    } cursor-pointer`}
                  >
                    {category}
                  </div>
                </div>
              ))}
          </div>
          <div className="w-full md:w-2/3  md:p-10">
            {jsonData &&
              data[jsonData].map((data, index) => (
                <div key={index} className="p-2">
                  <EmployeesDetailsCard
                    name={data.name}
                    graduation={
                      data.graduated ? "(" + data.graduated + ")" : ""
                    }
                    currently={
                      data.Currently ? "Currently : " + data.Currently : ""
                    }
                    past={
                      data.past_associations
                        ? "Past Associations : " + data.past_associations
                        : ""
                    }
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
