import React from 'react'
import { readmeComments } from '../../constants/data/readmeComment'
const ReadmeContent = () => {


    return (
        <div className='bg-[#ECF2FE]'>
          

            <div className='p-10 flex flex-col gap-y-7'>
            <div className="text-[30px] 2xl:text-[40px] font-semibold not-italic text-[#2C2C2C] leading-tight ">
                README
            </div>
                <span className="text-[#646464] text-[18px] leading-8 2xl:leading-[43px] 2xl:text-[24px] font-normal ">
                    While a detailed GitHub repository containing the pre-training, fine-tuning and inference scripts would be released soon, one may make use of the following code snippets to decode using the fine-tuned models that have been released.
                </span>

                {/* Pre-requisites*/}
                <div className='mt-4'>
                    <div className="text-[30px] 2xl:text-[40px] font-semibold not-italic text-[#2C2C2C] leading-tight border-b-2">
                        Pre-requisites
                    </div>
                    <div className='mt-5'>
                        <div className="text-[#646464] text-[18px] leading-8 2xl:leading-[43px] 2xl:text-[24px] font-normal ">
                            In order to infer using the fine-tuned models, an existing installation of the fairseq repository is needed.
                            <div>
                                A good practice is to install <a className='text-blue-500 underline' href="https://github.com/facebookresearch/fairseq/releases/tag/v0.12.2" target='_blank'>the v0.12.2 release </a>  of the same.
                            </div>
                            <div className='pt-3'>
                                Evaluating a CTC model (the fine-tuned models are CTC-based)
                                <div>
                                    requires <a className='text-blue-500 underline' href="https://github.com/flashlight/flashlight/tree/main/bindings/python" target='_blank'>flashlight python bindings</a> , previously called <a className='underline text-blue-500' href="https://github.com/flashlight/wav2letter" target='_blank'>wav2letter</a> to be installed.
                                </div>
                            </div>
                        </div>



                    </div>

                </div>

                {/* Data Preparation */}

                <div>
                    <div className="text-[30px] 2xl:text-[40px] font-semibold not-italic text-[#2C2C2C] leading-tight border-b-2">
                        Data Preparation
                    </div>
                    <div className="text-[#646464] flex flex-col gap-3 mt-5 text-[18px] leading-8 2xl:leading-[43px] 2xl:text-[24px] font-normal ">
                        <div>
                            To decode a dataset of audios, a <span className='bg-gray-300 px-3'>.tsv</span> file needs to be prepared containing the paths to each of the audio files to be inferred and the corresponding number of frames per audio.
                        </div>
                        <div>
                            It is to be noted that all of the audios to be inferred must be having a sampling rate of 16kHz.
                        </div>
                        <div>
                            Following is an example of the <span className='bg-gray-300 px-3'>.tsv</span> file structure:

                        </div>
                    </div>

                    <div className='px-10 md:px-20 '>
                        <div className='p-10 overflow-x-auto  bg-gray-100 text-[#646464]  flex flex-col mt-5 text-[18px] leading-8 2xl:leading-[43px] 2xl:text-[24px] font-normal '>
                            {
                                readmeComments.firstComment
                            }
                        </div>
                    </div>

                </div>

                {/* Setting the variable names to be used for inference */}

                <div>
                    <div className="text-[30px] 2xl:text-[40px] font-semibold not-italic text-[#2C2C2C] leading-tight border-b-2">
                        Setting the variable names to be used for inference

                    </div>
                    <div className="text-[#646464] flex flex-col gap-3 mt-5 text-[18px] leading-8 2xl:leading-[43px] 2xl:text-[24px] font-normal ">
                        <div>
                            <span className='bg-gray-200 px-1'> fairseq_installation_path</span> --{'>'} Path to the directory where fairseq has been installed.


                        </div>
                        <div>
                            <span className='bg-gray-200 px-1'> test_set_path</span> --{'>'} Paths to the different test set prepared in fairseq format to infer on containing the <span className='bg-gray-200 px-1'>.tsv</span> file.
                        </div>
                        <div>
                            <span className='bg-gray-200 px-1'>tsv_file_name</span> --{'>'} Name of the <span className='bg-gray-200 px-1'>.tsv</span> file to infer without the extension. Eg: if it is set1.tsv, <span className='bg-gray-200 px-1'>tsv_file_name</span> should be set to set1.

                        </div>
                        <div>
                            <span className='bg-gray-200 px-1'> checkpoint_path</span> --{'>'} Path to the checkpoint which is to be used for inference.
                        </div>
                        <div>
                            <span className='bg-gray-200 px-1'> result_dir_path</span> --{'>'} Directory where the inference results (hypothesis and reference) are to be stored.
                        </div>
                    </div>
                </div>

                {/* Inference command snippets*/}
                <div>
                    <div className="text-[30px] 2xl:text-[40px] font-semibold not-italic text-[#2C2C2C] leading-tight border-b-2">
                        Inference command snippets
                    </div>
                    <div className='py-4 text-[20px] 2xl:text-[25px] font-bold text-gray-600'>
                        For data2vec-aqc fine-tuned models

                    </div>
                    <div className="text-[#646464] flex flex-col gap-3 mt-5 text-[18px] leading-8 2xl:leading-[43px] 2xl:text-[24px] font-normal ">
                        <div>
                            In order to infer on the data2vec-aqc fine-tuned models, please install <a className='text-blue-500 underline' href="https://github.com/Speech-Lab-IITM/data2vec-aqc" target='_blank'>data2vec-aqc</a> and set the <span className='bg-gray-200 px-1'>fairseq_installation_path</span> to the location where data2vec-aqc has been installed.
                        </div>

                        <div className='px-10 md:px-20'>
                            <div className='p-10 bg-gray-100 overflow-x-auto text-[#646464] flex flex-col mt-5 text-[18px] leading-8 2xl:leading-[43px] 2xl:text-[24px] font-normal '>
                                {
                                    readmeComments.secondComent
                                }
                            </div>
                        </div>

                    </div>
                </div>

                {/* For wav2vec 2.0, ccc-wav2vec 2.0, IndicWav2Vec and MMS fine-tuned models */}

                <div>
                    <div className="text-[30px] 2xl:text-[40px] font-semibold not-italic text-[#2C2C2C] leading-tight border-b-2">
                        For wav2vec 2.0, ccc-wav2vec 2.0, IndicWav2Vec and MMS fine-tuned models
                    </div>

                    <div className="text-[#646464] flex flex-col gap-3 mt-5 text-[18px] leading-8 2xl:leading-[43px] 2xl:text-[24px] font-normal ">

                        <div className='px-10 md:px-20 '>
                            <div className='p-10 bg-gray-100 overflow-x-auto text-[#646464] flex flex-col mt-5 text-[18px] leading-8 2xl:leading-[43px] 2xl:text-[24px] font-normal '>
                                {
                                    readmeComments.thirdComent
                                }
                            </div>
                        </div>

                    </div>
                </div>


                {/* For data2vec fine-tuned models*/}
                <div>
                    <div className="text-[30px] 2xl:text-[40px] font-semibold not-italic text-[#2C2C2C] leading-tight border-b-2">
                        For data2vec fine-tuned models

                    </div>

                    <div className="text-[#646464] flex flex-col gap-3 mt-5 text-[18px] leading-8 2xl:leading-[43px] 2xl:text-[24px] font-normal ">

                        <div className='px-10 md:px-20'>
                            <div className='p-10 overflow-x-auto bg-gray-100 text-[#646464] flex flex-col mt-5 text-[18px] leading-8 2xl:leading-[43px] 2xl:text-[24px] font-normal '>
                                {
                                    readmeComments.fourthComent
                                }
                            </div>
                        </div>

                    </div>
                </div>

                {/* For HuBERT fine-tuned models*/}
                <div>
                    <div className="text-[30px] 2xl:text-[40px] font-semibold not-italic text-[#2C2C2C] leading-tight border-b-2">
                        For HuBERT fine-tuned models


                    </div>

                    <div className="text-[#646464] flex flex-col gap-3 mt-5 text-[18px] leading-8 2xl:leading-[43px] 2xl:text-[24px] font-normal ">

                        <div className='px-10 md:px-20'>
                            <div className='p-10 overflow-x-auto bg-gray-100 text-[#646464] flex flex-col mt-5 text-[18px] leading-8 2xl:leading-[43px] 2xl:text-[24px] font-normal '>
                                {
                                    readmeComments.fifthComent
                                }
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    )
}

export default ReadmeContent