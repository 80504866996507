import React from "react";
const asrChallengeData = require("../../../../constants/dataset/downloadData/asrChallengeData.json");
const dataBalance = require("../../../../constants/dataset/downloadData/dataBalance.json");
const nptelData = require("../../../../constants/dataset/downloadData/nptelData.json");
const HoursLinkTable = () => {
  return (
    <div>
      <div className="flex justify-between w-full gap-x-7">
        <div className="w-[50%]">
          <div className="text-center my-7 text-[17px] font-medium">
            <div>Indian Language ASR Challenge Data 490 Hours Links </div>
            <div></div>
          </div>
          <div>
            <div className="w-full  rounded-2xl bg-[#FFF] tableAlignment ">
              <table className="w-full ">
                <thead className="w-full text-[#363636] text-[16px] font-normal not-italic bg-gray-100 tableAlignment ">
                  <tr>
                    <th> English 190 hrs</th>
                    <th> Hindi 188 hrs</th>
                    <th> Tamil 112 hrs</th>
                  </tr>
                </thead>
                <tbody className="text-[#363636] font-normal text-[13px] not-italic text-center ">
                  {asrChallengeData?.map((item, i) => {
                    return (
                      <tr key={i} className="text-blue-600">
                        <td>
                          <a href={item.English_hrs.link}>
                            {item.English_hrs.name}
                          </a>
                        </td>

                        <td>
                          <a href={item.Hindi_hrs.link}>
                            {item.Hindi_hrs.name}
                          </a>
                        </td>
                        <td>
                          <a href={item.Tamil_hrs.link}>
                            {item.Tamil_hrs.name}
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* /////////////// */}
        <div className="w-[50%]">
          <div className="text-center my-7 text-[17px] font-medium ">
            <div className="w-full break-words">
              Indian Language Data Balance (Excluding the Challenge Data) 410 Hours Links
            </div>
            <div></div>
          </div>
          <div>
            <div className="w-full  rounded-2xl bg-[#FFF] tableAlignment ">
              <table className="w-full ">
                <thead className="w-full text-[#363636] text-[16px]font-normal not-italic bg-gray-100 tableAlignment ">
                  <tr>
                    <th> English 110 hrs</th>
                    <th> Hindi 262 hrs</th>
                    <th> Tamil 38 hrs</th>
                  </tr>
                </thead>
                <tbody className="text-[#363636] font-normal text-[13px] not-italic text-center ">
                  {dataBalance?.map((item, i) => {
                    return (
                      <tr key={i} className=" text-blue-600">
                        <td>
                          <a href={item.english.link}>{item.english.name}</a>
                        </td>
                        <td>
                          <a href={item.hindi.link}>{item.hindi.name}</a>
                        </td>
                        <td>
                          <a href={item.tamil.link}>{item.tamil.name}</a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* main table */}
      <div className="mt-10">
        <div className="text-center my-7 text-[17px] font-medium ">
          NPTEL Data 1200 Hours Links
        </div>
        <div className="w-full  rounded-2xl bg-[#FFF] tableAlignment ">
          <table className="w-full ">
            <thead className="w-full text-[#363636] text-[16px] font-normal not-italic bg-gray-100 tableAlignment ">
              <tr>
                <th>Computer Science 250 hrs</th>
                <th> Electrical 250 hrs</th>
                <th> Humanities 250 hrs</th>
                <th> Mechanical 250 hrs</th>
                <th>BioChem 200 hrs</th>
              </tr>
            </thead>
            <tbody className="text-[#363636] font-normal text-[16px] not-italic text-center ">
              {nptelData?.map((item, i) => {
                return (
                  <tr key={i} className="text-blue-600">
                    <td>
                      <a href={item.computer.link}>{item.computer.name}</a>
                    </td>
                    <td>
                      <a href={item.electrical.link}>{item.electrical.name}</a>
                    </td>
                    <td>
                      <a href={item.humanities.link}>{item.humanities.name}</a>
                    </td>
                    <td>
                      <a href={item.mechanical.link}>{item.mechanical.name}</a>
                    </td>
                    <td>
                      <a href={item.bioChem.link}>{item.bioChem.name}</a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default HoursLinkTable;
