import React from 'react'
import EmplyeesHead from '../component/Employee/EmplyeesHead'
import EmployeesContainer from '../component/Employee/EmployeesContainer'

const Employees = () => {
  return (
    <div>
        <EmplyeesHead/>
        <EmployeesContainer/>
    </div>
  )
}

export default Employees