export const PublicationLists = [
  {
    id: 1,
    option: "A: Ongoing and Recent Projects",
    list: [
      {
        author: "Speech R&D Consortium of Indian Bhashini Project",
        years: "April 2022 – Present",
        link: "https://bhashini.gov.in/",
        topic: (
          <span>
            Indian Ministry of Electronics & Information Tech. <br />
            Funding: Rs. 500 million (≈ USD 6 million) <br />
            Project by a consortium of 23 Institutions <br />
            National Consortium Leader & Chief Investigator: S. Umesh,
          </span>
        ),
      },
      {
        author:
          "ASR for National Language Technology Mission (Bhashini - Pilot)",
        years: "Apr 2020 – Mar 2022",
        link: "",
        topic: (
          <span>
            Indian Ministry of Electronics & Information Tech. <br />
            Funding: Rs. 32.73 million (≈ USD 400,000) <br />
            Chief Investigator: S. Umesh,
          </span>
        ),
      },
      {
        author:
          "ASR in Indian English, Hindi & Tamil Speech to Speech Machine Translation - Pilot",
        years: "April 2020 – July 2021",
        link: "",
        topic: (
          <span>
            Office of Principal Scientific Advisor, Govt. of India <br />
            Funding: Rs. 19.98 million (≈ USD 240,000) <br />
            Co-PI: S. Umesh (PI: Prof Hema Murthy),
          </span>
        ),
      },
      {
        author:
          "Multilinguality in Speech Technologies Joint Research Programme of Indian-DST and Czech-MEYS",
        years: "Aug 2020 – July 2023",
        link: "",
        topic: (
          <span>
            Funding: Rs. 3.84 million (≈ USD 48,000) <br />
            PIs: S. Umesh & Jan “Honza” Cernock ́y ˇ
          </span>
        ),
      },
      {
        author: "Speech-Based Access of Commodity Prices in Indian Langs",
        years: "2010-2013, 2014 - 2017",
        link: "",
        topic: (
          <span>
            Indian Ministry of Electronics & Information Tech. <br />
            Funding: Rs. 120 million(≈ USD 1.5 Million) <br />
            Project by a consortium of 12 Institutions in Two Phases <br />
            National Consortium Leader & Chief Investigator: S. Umesh,
          </span>
        ),
      },
      {
        author:
          "Front-end signal processing for robust speech recognition and speaker adaptation/normalization",
        years: "2010-2013",
        link: "",
        topic: (
          <span>
            Dept. of Science & Technology <br />
            Funding: Rs. 3.30 million (≈ USD 40,000) <br />
            Project under Indo-Spanish Joint Cooperation Programme <br />
            Principal Investigators: S. Umesh (India) and Carmen Benitez
            (Spain)`,
          </span>
        ),
      },
      {
        author: "Linear Transformation Approach to VTLN",
        years: "2009-2013",
        link: "",
        topic: (
          <span>
            Dept. of Science & Technology <br />
            Funding: Rs. 3.20 million (≈ USD 40,000) <br />
            Principal Investigator: S. Umesh,
          </span>
        ),
      },
    ],
  },
];
