import React from "react";
import "../../Demo/Demo1/Body.css";
import { useState } from "react";
import Model from "./tabBody/Model";
import Api from "./tabBody/Api";
import Faq from "./tabBody/Faq";
import LeaderBoard from "./tabBody/LeaderBoard";
import AsrTestSet from "./tabBody/AsrTestSet";

const modelTabs = [
  { name: "Models" },
  { name: "Leader Board" },
  { name: "ASR Test Sets" },
  { name: "API" },
];

const Body = () => {
  const [activeTab, setActiveTab] = useState("Models");
  const changetab = (tabName) => {
    if (tabName === "API") {
      window.open(
        "https://speech-lab-iitm.github.io/docs/api_docs/asr/",
        "_blank"
      );
      return;
    }
    setActiveTab(tabName);
  };

  return (
    <>
      <section className="w-full bg-[#FFF] flex flex-col gap-y-10 px-6 md:px-36 mt-12  ">
        <div className="w-full flex gap-x-3">
          {modelTabs.map((item) => {
            return (
              <div
                key={item.name}
                onClick={() => changetab(item.name)}
                className={`px-2 py-2 text-center text-[15px] rounded-lg cursor-pointer font-semibold w-40 not-italic ${
                  activeTab === item.name
                    ? "bg-[#030F30] text-white"
                    : " bg-[#ECF2FE] text-[#030F30]"
                }`}
              >
                {item.name}
              </div>
            );
          })}
        </div>

        {activeTab === "Models" ? <Model setActiveTab={setActiveTab} /> : ""}
        {activeTab === "Leader Board" ? <LeaderBoard /> : ""}
        {activeTab === "FAQ" ? <Faq /> : ""}
        {activeTab === "ASR Test Sets" ? <AsrTestSet /> : ""}
      </section>
    </>
  );
};

export default Body;
