import React from 'react'
import Nav from '../component/Demo/Nav'
import Footer from '../component/Demo/Footer'
import Body from '../component/Demo/Demo1/Body'
import { ImageUrl } from '../constants/url/ImagePath'
import Navbar from '../component/Shared/Navbar'
import Header from '../component/Demo/Demo1/Header'

// let headerData = " SPRING-INX SSL and ASR Models"


const Demo1 = () => {
  return (
    <>
      {/* <Header />
        <Nav />
      
        <Footer /> */}
      < Header  />
      <Body />
    </>

  )
}

export default Demo1