import React from 'react'
import Body from '../component/Demo/Demo3/Body'
import Header from '../component/Demo/Demo3/Header'

const Demo3 = () => {
    

    return (
        <div>
            <Header />
            <section className="w-full bg-[#FFF] flex flex-col gap-y-10 mt-5 2xl:mt-14" style={{ padding: "43px 115px 43px 115px" }}>
                <Body />
            </section>

        </div>
    )
}

export default Demo3