

export const readmeComments = {

    firstComment: (
        <span className="flex flex-col gap-3">
            <div>
                {
                    ' <Most-common-path-on-the-file-system-between-all-the-audio-files-to-be-inferred>'
                }
            </div>
            <div>
                {
                    ' <rest-of-the-path-to-audio-file1-if-the-first-line-is-prefixed-to-this> <number-of-frames-in-audio-file1>'
                }
            </div>
            <div>
                {
                    ' <rest-of-the-path-to-audio-file2-if-the-first-line-is-prefixed-to-this> <number-of-frames-in-audio-file2> '

                }
            </div>
            <div>
                {
                    ' <rest-of-the-path-to-audio-file3-if-the-first-line-is-prefixed-to-this> <number-of-frames-in-audio-file3>'
                }
            </div>
            <div>
                {
                    ' ...'
                }
            </div>
            <div>
                {
                    '<rest-of-the-path-to-audio-file n-if-the-first-line-is-prefixed-to-this> <number-of-frames-in-audio-file n>'
                }
            </div>
        </span>


    ),

    secondComent: (
        <span>
            <div>
                {
                    '# Set the following paths according to the above section on setting the variable names fairseq_installation_path=<path_to_the_installation_of_data2vec-aqc>'
                }
            </div>
            <div className="flex flex-col gap-3 mt-5">
                <div>{'test_set_path='}</div>
                <div>{'tsv_file_name='}</div>
                <div>{'checkpoint_path='}</div>
                <div>{'result_dir_path='}</div>
            </div>
            <div className="flex flex-col gap-3 mt-5">
                <div>
                    {
                        'python3 ${fairseq_installation_path}/fairseq-0.12.2/examples/speech_recognition/new/infer.py /'
                    }
                </div>
                <div>
                    {
                        '--config-dir ${fairseq_installation_path}/fairseq-0.12.2/examples/speech_recognition/new/conf \
                    '
                    }
                </div>
                <div>
                    {
                        '--config-name infer task=audio_finetuning task.data=${test_set_path} common.user_dir=${fairseq_installation_path}/fairseq-0.12.2/examples/data2vec \
                    '
                    }
                </div>
                <div>
                    {
                        'task.labels=ltr decoding.type=viterbi \
                    '
                    }
                </div>
                <div>
                    {
                        'decoding.results_path=${result_dir_path} \
                    '
                    }
                </div>
            </div>

        </span>
    ),

    thirdComent: (
        <span>
            <div>
                {
                   '# Set the following paths according to the above section on setting the variable names fairseq_installation_path='
                }
            </div>

            <div className="flex flex-col gap-3 mt-5">
                <div>{'test_set_path='}</div>
                <div>{'tsv_file_name='}</div>
                <div>{'checkpoint_path='}</div>
                <div>{'result_dir_path='}</div>
            </div>

            <div className="flex flex-col gap-3 mt-5">
                <div>
                    {
                        'python3 ${fairseq_installation_path}/fairseq-0.12.2/examples/speech_recognition/infer.py \
                        '
                    }
                </div>
                <div>
                    {
                        '${test_set_path} \
                        '
                    }
                </div>
                <div>
                    {
                        '--task audio_finetuning --nbest 1 --path ${checkpoint_path} --gen-subset ${tsv_file_name} \
                        '
                    }
                </div>
                <div>
                    {
                        '--results-path ${result_dir_path} --beam 1500 \
                        '
                    }
                </div>
                <div>
                    {
                        '--w2l-decoder viterbi --sil-weight 0 --criterion ctc --labels ltr --max-tokens 4000000 \
                        '
                    }
                </div>
                <div>
                    {
                        '--post-process letter'
                    }
                </div>
               
            </div>
        </span>
    ),
    fourthComent: (
        <span>
            <div>
                {
                   '# Set the following paths according to the above section on setting the variable names fairseq_installation_path='
                }
            </div>

            <div className="flex flex-col gap-3 mt-5">
                <div>{'test_set_path='}</div>
                <div>{'tsv_file_name='}</div>
                <div>{'checkpoint_path='}</div>
                <div>{'result_dir_path='}</div>
            </div>

            <div className="flex flex-col gap-3 mt-5">
                <div>
                    {
                        'python3 ${fairseq_installation_path}/fairseq-0.12.2/examples/speech_recognition/new/infer.py \
                        '
                    }
                </div>
                <div>
                    {
                        '--config-dir ${fairseq_installation_path}/fairseq-0.12.2/examples/speech_recognition/new/conf \
                        '
                    }
                </div>
                <div>
                    {
                        '--config-name infer task=audio_finetuning task.data=${test_set_path} common.user_dir=${fairseq_installation_path}/fairseq-0.12.2/examples/data2vec \
                        '
                    }
                </div>
                <div>
                    {
                        'task.labels=ltr decoding.type=viterbi \
                        '
                    }
                </div>
                <div>
                    {
                        'decoding.results_path=${result_dir_path} \
                        '
                    }
                </div>
                <div>
                    {
                        'decoding.silweight=0 \
                        '
                    }
                </div>
                <div>
                    {
                        'dataset.gen_subset=${tsv_file_name} \
                        '
                    }
                </div>
                <div>
                    {
                        'common_eval.results_path=${result_dir_path} \
                        '
                    }
                </div>
                <div>
                    {
                        'common_eval.path=${checkpoint_path} decoding.beam=1500 distributed_training.distributed_world_size=1'
                    }
                </div>
               
            </div>
        </span>
    ),
    fifthComent: (
        <span>
            <div>
                {
                   '# Set the following paths according to the above section on setting the variable names fairseq_installation_path='
                }
            </div>

            <div className="flex flex-col gap-3 mt-5">
                <div>{'test_set_path='}</div>
                <div>{'tsv_file_name='}</div>
                <div>{'checkpoint_path='}</div>
                <div>{'result_dir_path='}</div>
            </div>

            <div className="flex flex-col gap-3 mt-5">
                <div>
                    {
                        'python3 ${fairseq_installation_path}/fairseq-0.12.2/examples/speech_recognition/new/infer.py \
                        '
                    }
                </div>
                <div>
                    {
                        '--config-dir ${fairseq_installation_path}/fairseq-0.12.2/examples/hubert/config/decode \
                        '
                    }
                </div>
                <div>
                    {
                        '--config-name infer_viterbi +task=audio_finetuning task.data=${test_set_path} \
                        '
                    }
                </div>
                <div>
                    {
                        'common_eval.path=${checkpoint_path} \
                        '
                    }
                </div>
                <div>
                    {
                        'decoding.results_path=${result_dir_path} \
                        '
                    }
                </div>
                <div>
                    {
                        'decoding.silweight=0 \
                        '
                    }
                </div>
                <div>
                    {
                        'dataset.gen_subset=${tsv_file_name} \
                        '
                    }
                </div>
                <div>
                    {
                        'common_eval.results_path=${result_dir_path} \
                        '
                    }
                </div>
                <div>
                    {
                        'task.normalize=true \
                        '
                    }
                </div>
                <div>
                    {
                        'decoding.beam=1500 distributed_training.distributed_world_size=1 \
                        '
                    }
                </div>
                <div>
                    {
                        'dataset.max_tokens=4000000 \ '
                    }
                </div>
                <div>
                    {
                        'common_eval.quiet=true'
                    }
                </div>
               
            </div>
        </span>
    )

}