import { NavLink } from "react-router-dom";
import { ImageUrl } from "../url/ImagePath";
import InitialTable from "../../component/Demo/Demo1/tabBody/modelTables/InitialTable";
import FirstTable from "../../component/Demo/Demo1/tabBody/modelTables/FirstTable";
import { SecondTable } from "../../component/Demo/Demo1/tabBody/modelTables/SecondTable";
import { HomeUrl } from "../url/HomePageUrl";
import { Thirdtable } from "../../component/Demo/Demo1/tabBody/modelTables/Thirdtable";
export const modelJson = [

    {
        id: 1,
        header: (
            <span>SPRING-INX Foundation {""} <span className="text-[#CB8461]">Model</span></span>
        ),
        description: (
            <span>The Foundation models are trained with <span className="font-bold">30k hours</span> of raw speech data across <span className="font-bold">24 Indian languages</span>. The embeddings from these foundational models will benefit various speech applications, including speaker identification, language identification, speaker diarization, in addition to automatic speech recognition (ASR) and text-to-speech (TTS) systems.,</span>
        ),
        imgUrl: ImageUrl.foundation,
        table: <InitialTable />,
        gitHubLink: ''
    },
    {
        id: 2,
        header: (
            <span>SPRING-INX ASR fine-tuned {""} <span className="text-[#CB8461]" >models</span> </span>
        ),
        description: (
            <span>Our recent {""} <NavLink to="/models" className="text-blue-500 cursor-pointer">ccc-wave2vec2.0</NavLink> and {""}<NavLink to="/models" className="text-blue-500 cursor-pointer">data2vec-aqc</NavLink> models are showcased on the {""}<NavLink to="/leaderBoard" className="text-blue-500 cursor-pointer">leaderboard</NavLink> , demonstrating their impressive performance.</span>
        ),
        imgUrl: ImageUrl.languageimg,
        table: <FirstTable />,
        gitHubLink: ''
    },
    {
        id: 3,
        header: (
            <span>SPRING-INX Fine-tuned {''} <span className="text-[#CB8461]">models using</span> Publicly available SSL models</span>
        ),
        description: (
            <span>
                Using the Indian languages ASR data, we also fine-tune two publicly
                available SSL models, namely the{" "}
                <a className="text-blue-500" href={HomeUrl.Massively_Multilingual_Speech} target="_blank">
                    Massively Multilingual Speech
                </a>{" "}
                and{" "}
                <a className="text-blue-500" href={HomeUrl.Indicwav2vec} target="_blank">IndicWav2vec.</a> You can download
                these SPRING LAB fine-tuned models in the links given below
            </span>
        ),
        imgUrl: ImageUrl.model3img,
        table: <SecondTable />,
        gitHubLink: ''
    },
    {
        id: 4,
        header: (
            <span>SPRING Lab {''} <span className="text-[#CB8461]">SSL models</span> Librispeech960</span>
        ),
        description: (
            <span>You can download the pretrained and fine-tuned ccc-wav2vec2.0 and data2vec-aqc model built using the Librispeech-960 hours data. These models are currently ranked 5th and 6th on Public dataset of {""} <a className="text-blue-500" href="https://superbbenchmark.org/leaderboard?subset=Public+Set" target="_blank">SUPERB Challenge</a> </span>
        ),
        imgUrl: ImageUrl.model4img,
        table: <Thirdtable />,
        gitHubLink: ''
    },
    {
        id: 5,
        header: `Readme`,
        description: (
            <NavLink to="/readme" target="_blank">
                For Batch inference refer to <a target="_blank" href="https://asr.iitm.ac.in/readme" className="text-blue-500">readme</a> <br />
                For single file inference refer to <a target="_blank" className="text-blue-500" href="https://github.com/Speech-Lab-IITM/Fairseq-Inference">github repo</a>
            </NavLink>
        ),
        imgUrl: ImageUrl.readme,
        table: '',
        gitHubLink: ''
    },
    {
        id: 6,
        header: `Acknowledgment`,
        description: `We thank CDAC for generously providing PARAM Siddhi compute facilities to train our models.`,
        imgUrl: ImageUrl.acknowledment,
        table: '',
        gitHubLink: ''
    },

]

