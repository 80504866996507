import React from 'react'

const Odd = ({ data }) => {
    return (
        <div key={data.id} className='w-full flex flex-col gap-y-10'>
            <div className="w-full rounded-xl p-[30px] 2xl:p-[50px] flex flex-col gap-y-10 bg-[#ECF2FE]">

                <div className='flex w-full min-w-[200px] min-h-[200px] gap-10'>
                    <div className='w-[40%] relative hidden md:block'>
                        {data.imgUrl === "" ? "" : <img src={data.imgUrl} className='w-full h-full absolute' alt="" />}
                    </div>
                    <div className='w-full md:w-[60%] flex flex-col gap-3'>
                        <div className="text-[32px] 2xl:text-[40px] font-semibold not-italic text-[#2C2C2C] leading-tight">{data.header}</div>
                        <div className="text-[#646464] text-[18px] leading-8 2xl:leading-[43px] 2xl:text-[24px] font-normal ">{data.description}</div>
                    </div>
                </div>
                {data.table}
            </div>

        </div>
    )
}

export default Odd