import React from 'react'
import Even from '../../../zigZag/Even'
import Odd from '../../../zigZag/Odd'
import { dataSetJson } from '../../../../constants/data/dataSetPageJson'

export const DownloadData = () => {
    return (
        <>
            {dataSetJson?.map((data, index) => {
                if (data.id % 2 === 0) {
                    return (
                        <div key={data.id}>
                            <Even data={data} />
                        </div>
                    )
                }
                else {
                    return (
                        <div key={data.id}>
                            <Odd data={data} />
                        </div>
                    )
                }
            })}
        </>
    )
}
