import React from 'react'
import { asrRecipesData } from '../../../../constants/data/asrRecipespageJson'
export const AsrReceipes = () => {
  return (
    <>
      {
        asrRecipesData.map((data) => {
          return (
            <div key={data.id} className='w-full flex flex-col gap-y-10'>
              <div className="w-full rounded-xl p-[30px] 2xl:p-[50px] flex flex-col gap-y-10 bg-[#ECF2FE]">
                <div className='flex  min-w-[200px] min-h-[200px] gap-10'>
                  <div className='w-full md:w-[60%] flex flex-col gap-3'>
                    <div className="text-[32px] 2xl:text-[40px]  font-semibold not-italic text-[#2C2C2C] leading-tight">{data.header}</div>
                    <div className="text-[#646464] text-[18px] 2xl:leading-[43px] 2xl:text-[24px] font-normal ">{data.description}</div>
                    <div>
                      {data.gitHubLink}
                    </div>
                  </div>
                  <div className='hidden md:w-[40%]'>
                    <div className={`h-full w-full relative`}>
                      {data.imgUrl === "" ? "" : <img src={data.imgUrl} className='w-full h-full object-contain absolute' alt="" />}
                    </div>
                  </div>
                </div>
               
              </div>

            </div>
          )
        })
      }

    </>
  )
}
