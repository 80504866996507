// Layout.jsx
import React from 'react';
import { Outlet } from 'react-router-dom';
import ScrollToTop from './Shared/ScrollComponent';

const Layout = () => {
  return (
    <div>
      <ScrollToTop /> {/* Include ScrollToTop component */}
      <main>
        <Outlet /> {/* This renders the matched route's component */}
      </main>
      {/* Optionally include Footer here if it's not conditionally rendered */}
    </div>
  );
};

export default Layout;
