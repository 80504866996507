import "./App.css";
import AllContentLoaded from "./Pages/HomePage";
import { Routes, Route, useLocation } from "react-router-dom";
import Footer from "./component/Shared/Footer";
import Projects from "./Pages/Projects";
import LoadedContactMap from "./component/Contact/Contact";
import Alumini from "./Pages/Alumini";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AllGalleryContentLoading from "./Pages/AllGalleryContentLoading";
import Publication from "./Pages/Publication.jsx";
import Demo1 from "./Pages/Demo1";
import Demo2 from "./Pages/Demo2";
import Demo3 from "./Pages/Demo3";
import { Provider } from 'react-redux';
import { store } from "./Redux/Store";
import Readme from "./Pages/Readme";
import authService from "./service/authService";
import { useEffect } from "react";
import Layout from "./component/Layout.jsx";
import Employees from "./Pages/Employees.jsx";
import Project from "./Pages/Project.jsx";

function App() {
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    authService.login({ "email": "iitnetphenix@gmail.com", "password": "12345" }).then((data) => {
      if (data?.status && data?.status === "Error credentials") {
        return;
      }
      if (data?.token) {
        localStorage.setItem("token", JSON.stringify(data.token));
      }
    });
  }, []);

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Spring Lab, IIT Madras</title>
          <meta name="description" content="Our focus is on building state of the art speech recognition systems, especially in Indian languages. Our research interests are in low - resource modelling, multilingual speech recognition and speaker normalisation." />
          <meta name="keywords" content="Spring Lab, ASR, IIT Madras, Speech, Translator, TTS, Indian Languages, Speech Recognition, Language Translation, Video to Video,Dr S.Umesh, Speech and Music Technology, National Language Translation, NLTM, SSMT, Automatic Speech Recognition" />
          <link rel="home" href="http://asr.iitm.ac.in:8080/" />
        </Helmet>
      </HelmetProvider>
      <Provider store={store}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<AllContentLoaded />} />
            <Route path="contact" element={<LoadedContactMap />} />
            <Route path="hiring" element={<Projects />} />
            <Route path="alumni" element={<Alumini />} />
            <Route path="gallery" element={<AllGalleryContentLoading />} />
            <Route path="publications" element={<Publication />} />
            <Route path="models" element={<Demo1 />} />
            <Route path="dataset" element={<Demo2 />} />
            <Route path="leaderBoard" element={<Demo3 />} />
            <Route path="readme" element={<Readme />} />
            <Route path="employees" element={<Employees />} />
            <Route path="project" element={<Project />} />

            
          </Route>
        </Routes>
        {currentPath !== "/readme" && <Footer />}
      </Provider>
    </div>
  );
}

export default App;
