import React, { useEffect, useState } from 'react'
import { RiArrowUpSLine, RiArrowDropDownLine } from 'react-icons/ri';


const FAQLIst = ({ item }) => {
    const [isShowChild, setIsShowChild] = useState(false)

    const handleIsShowChild = () => {
        setIsShowChild(!isShowChild)
    }
    return (
        <>
            <div onClick={handleIsShowChild} className="w-full flex justify-between items-center p-[20px] cursor-pointer 2xl:p-[30px] rounded-lg bg-[#ECF2FE]" >
                <div className='text-red-800'>
                    {
                        item?.question
                    }
                </div>
                <div>
                    {!isShowChild ? <RiArrowUpSLine /> : <RiArrowDropDownLine size={20} />}
                </div>
            </div>
            {item?.children.length != 0 && item?.children?.map((child) => {
                return (
                    <div key={child.id}>
                        {isShowChild && <div className='bg-[#eceef1] text-gray-600 rounded-lg mt-2 w-full p-[20px] 2xl:p-[30px]'>
                            {
                                child.answer
                            }
                        </div>}
                    </div>
                )
            })}
        </>
    )
}

export default FAQLIst