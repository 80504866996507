import React from 'react'

const Even = ({ data }) => {
    return (
        <div className='w-full flex flex-col gap-y-10'>
            <div className="w-full rounded-xl p-[30px] 2xl:p-[50px] flex flex-col gap-y-10 bg-[#ECF2FE]">
                <div className={`${data.name==="no"?"h-0 py-5":"flex w-full min-w-[200px] min-h-[200px] gap-10"}`}>
                    <div className={` ${data.name==="no"?"w-full flex flex-col gap-3":"w-full md:w-[60%] flex flex-col gap-3" }`}>
                        <div className={`${data.name==="no"?"text-center text-[30px] 2xl:text-[40px] font-semibold not-italic text-[#2C2C2C] leading-tight":"text-[30px] 2xl:text-[40px] font-semibold not-italic text-[#2C2C2C] leading-tight"}`}>{data.header}</div>
                        <div className="text-[#646464] text-[18px] leading-8 2xl:leading-[43px] 2xl:text-[24px] font-normal ">{data.description}</div>
                    </div>
                    <div className='w-[40%] hidden md:block'>
                        {data.imgUrl === "../../static/Asset/images/treeFoundationImg.svg" || data.imgUrl === "../../static/Asset/images/foundationImg.svg" ? <div className='relative '>
                            <div className='absolute  w-72 h-72 2xl:w-96 2xl:h-96 ml-16 2xl:ml-32 2xl:bottom-[-260px] bottom-[-220px]'>
                                <img className='w-full h-full ' src={data.imgUrl} alt="" />
                            </div>
                        </div> : <div className={`h-full w-full relative hidden md:block`}>
                            {data.imgUrl === "" ? "" : <img src={data.imgUrl} className='w-full h-full object-contain absolute' alt="" />}
                        </div>}
                    </div>
                </div>
                {data.table}
            </div>

        </div>
    )
}

export default Even