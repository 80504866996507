import React from "react";
import TableLink from "../../../TableLink";

const tableData = [
  {
    name: "Commonvoice",
    link: "https://asr.iitm.ac.in/asr_test_sets/commonvoice.zip",
  },
  {
    name: "Fleurs",
    link: "https://asr.iitm.ac.in/asr_test_sets/fleurs.zip",
  },
  {
    name: "Gramvaani",
    link: "https://asr.iitm.ac.in/asr_test_sets/gramvaani.zip",
  },
  {
    name: "Indictts",
    link: "https://asr.iitm.ac.in/asr_test_sets/indictts.zip",
  },
  {
    name: "Kathbath",
    link: "https://asr.iitm.ac.in/asr_test_sets/kathbath.zip",
  },
  {
    name: "Kathbath_noisy",
    link: "https://asr.iitm.ac.in/asr_test_sets/kathbath_noisy.zip",
  },
  {
    name: "Mucs",
    link: "https://asr.iitm.ac.in/asr_test_sets/mucs.zip",
  },
];

const AsrtestsetTable = () => {
  return (
    <div className="w-full  rounded-2xl bg-[#FFF] tableAlignment">
      <table className="w-full ">
        <thead className="w-full text-[#363636] text-[16px] font-normal not-italic bg-gray-100 tableAlignment ">
          <tr>
            <th>
              <div className="flex items-center justify-center gap-x-2">
                <div> Dataset Name </div>
              </div>
            </th>
            <th>
              <div className="flex items-center justify-center gap-x-2">
                <div> Download Link</div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="text-[#363636] font-normal text-[13px] not-italic text-center ">
          {tableData?.map((item, i) => {
            return (
              <tr key={i} className="text-[#2C2C2C] cursor-pointer ">
                <td className="text-[#030F30] pointer-events-none">
                  {item.name}
                </td>
                <td>
                  <TableLink name={"Download"} link={item.link} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AsrtestsetTable;
