
export const asrRecipesData = [

    {
        id: 1,
        header: (
            <span>ASR
                {""} <span className="text-[#CB8461]">Recipes</span></span>
        ),
        description: `Please find below the link to the GitHub page which has the entire source code and recipes to build ASR models from scratch using the released SPRING—INX data.`,
        imgUrl: '',
        table: '',
        gitHubLink: (
            <span className="text-[#646464] text-[18px] 2xl:text-[24px]">Link to Github page - <a href="https://github.com/Speech-Lab-IITM/SPRING_INX_ESPnet_Recipe" target="_blank" className="text-blue-500 text-[15px] 2xl:text-[20px] cursor-pointer">ESPnet recipes</a></span>
        )
    },

]
