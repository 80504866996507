import React from "react";
import GetDataAscendingOrder from "../../../../service/sortService";

const tableData = require("../../../../constants/dataset/downloadData/table2.json");
const sorteddata = GetDataAscendingOrder(tableData, "language");
const SecondTable = () => {
  return (
    <div className="w-full rounded-2xl bg-[#FFF] tableAlignment ">
      <table className="min-w-full divide-y divide-gray-200 rounded-full">
        <thead className="bg-gray-100 rounded-full ">
          <tr className="">
            <th className="px-6 py-3  text-xs  font-bold text-[#363636] tracking-wider border border-t-0 border-b-0 border-l-0 border-gray-300 "></th>
            <th
              colSpan="4"
              className="px-6 py-3 text-center text-xs  font-bold text-[#363636] border-t-0  tracking-wider border border-gray-300"
            >
              Data_R1(hrs)
            </th>
            <th
              colSpan="4"
              className="px-6 py-3 text-center text-xs  font-bold text-[#363636] border-t-0 tracking-wider border border-gray-300"
            >
              Data_R2(hrs)
            </th>
            <th className="px-6 py-3 text-left text-xs  font-bold text-[#363636] border-t-0 tracking-wider border border-gray-300 border-b-0 border-r-0"></th>
          </tr>
          <tr>
            <th className="px-6 py-3 text-left text-xs  font-bold text-[#363636] tracking-wider border border-gray-300 border-t-0 border-l-0 border-b-0 ml-8 flex place-items-baseline">
              {" "}
              Languages
            </th>
            <th className="px-6 py-3 text-left text-xs  font-bold text-[#363636] tracking-wider border border-gray-300">
              train
            </th>
            <th className="px-6 py-3 text-left text-xs  font-bold text-[#363636] tracking-wider border border-gray-300">
              dev 
            </th>
            <th className="px-6 py-3 text-left text-xs  font-bold text-[#363636] tracking-wider border border-gray-300">
              eval 
            </th>
            <th className="px-6 py-3 text-left text-xs  font-bold text-[#363636] tracking-wider border border-gray-300">
              Total_R1
            </th>
            <th className="px-6 py-3 text-left text-xs  font-bold text-[#363636] tracking-wider border border-gray-300">
              train 
            </th>
            <th className="px-6 py-3 text-left text-xs  font-bold text-[#363636] tracking-wider border border-gray-300">
              dev 
            </th>
            <th className="px-6 py-3 text-left text-xs  font-bold text-[#363636] tracking-wider border border-gray-300">
              eval 
            </th>
            <th className="px-6 py-3 text-left text-xs  font-bold text-[#363636] tracking-wider border border-gray-300">
              Total_R2
            </th>
            <th className="px-6 py-3 text-left text-xs  font-bold text-[#363636] tracking-wider border border-gray-300 border-t-0 border-r-0  border-l-0 border-b-0 ml-8 flex place-items-baseline">
              Total
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {sorteddata?.map((item, i) => {
            return (
              <tr key={i} >
                <td
                  className={`${
                    item.language === "Total"
                      ? "font-bold"
                      : "px-3 py-2 whitespace-nowrap  border-gray-300"
                  }`}
                >
                  {item.language}
                </td>
                <td  className={`${
                    item.train === 1766.88
                      ? "font-bold"
                      : "px-3 py-2 whitespace-nowrap  border-gray-300"
                  }`}>
                  {item.train}
                </td>
                <td className={`${
                    item.dev === 187.5
                      ? "font-bold"
                      : "px-3 py-2 whitespace-nowrap  border-gray-300"
                  }`}>
                  {item.dev}
                </td>
                <td className={`${
                    item.evalhrs1 === 49.96
                      ? "font-bold"
                      : "px-3 py-2 whitespace-nowrap  border-gray-300"
                  }`}>
                  {item.evalhrs1}
                </td>
                <td  className={`${
                    item.Total_R1 === 2004.34
                      ? "font-bold"
                      : "px-3 py-2 whitespace-nowrap  border-gray-300"
                  }`}>
                  {item.Total_R1}
                </td>
                <td  className={`${
                    item.train1 === 1125.94
                      ? "font-bold"
                      : "px-3 py-2 whitespace-nowrap  border-gray-300"
                  }`}>
                  {item.train1}
                </td>
                <td  className={`${
                    item.dev1 === 131.42
                      ? "font-bold"
                      : "px-3 py-2 whitespace-nowrap  border-gray-300"
                  }`}>
                  {item.dev1}
                </td>
                <td className={`${
                    item.eval2 === 40.65
                      ? "font-bold"
                      : "px-3 py-2 whitespace-nowrap  border-gray-300"
                  }`}>
                  {item.eval2}
                </td>
                <td className={`${
                    item.Total_R2 === 1298.01
                      ? "font-bold"
                      : "px-3 py-2 whitespace-nowrap  border-gray-300"
                  }`}>
                  {item.Total_R2}
                </td>
                <td className={`${
                    item.Total === 3302.35
                      ? "font-bold"
                      : "px-3 py-2 whitespace-nowrap  border-gray-300"
                  }`}>
                  {item.Total}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SecondTable;
