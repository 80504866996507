import React from "react";
import AsrtestsetTable from "../../Demo2/asrTestSet/AsrTestSetTable";

const AsrTestSet = () => {
  return (
    <div className="w-full flex flex-col gap-y-10">
      <div className="w-full rounded-xl p-[30px] 2xl:p-[50px] flex flex-col gap-y-10 bg-[#ECF2FE]">
        <div
          className={`"h-0 py-5":"flex w-full min-w-[200px] min-h-[200px] gap-10"}`}
        >
          <div
            className={` w-full   gap-3":"w-full md:w-[60%] flex flex-col gap-3" }`}
          >
            <div
              className={`"text-center text-[30px]  flex gap-2 font-semibold not-italic  leading-tight":"text-[30px] 2xl:text-[40px]  text-[#2C2C2C] leading-tight"}`}
            >
              ASR
              <div className="text-[#CB8461]">Test</div>
              Sets
            </div>
            <div className="text-[#646464] text-[18px] leading-8 2xl:leading-[43px] 2xl:text-[24px] font-normal ">
              The test sets with which models developed by SPRING LAB in Indian
              Languages are tested. Scores (WER) are available in the leader
              board.
            </div>
          </div>
          <div className="w-[40%] hidden md:block"></div>
        </div>
        <AsrtestsetTable />
      </div>
    </div>
  );
};

export default AsrTestSet;
