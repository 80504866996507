import React from 'react'
import ReadmeContent from '../component/Readme/ReadmeContent'
const Readme = () => {
    return (
        <div className=' px-10 md:px-20 py-5'>
            <ReadmeContent />
        </div>
    )
}

export default Readme