import { ImageUrl } from "../url/ImagePath";
import { FirstTable } from "../../component/Demo/Demo2/dataSetTable/FirstTable";
import SecondTable from "../../component/Demo/Demo2/dataSetTable/SecondTable";
import HoursLinkTable from "../../component/Demo/Demo2/dataSetTable/HoursLinkTable";

export const dataSetJson = [
  {
    id: 2,
    header: (
      <span>
        Manually Transcribed {""}{" "}
        <span className="text-[#CB8461]">Multilingual</span> Indian Speech
        Corpus
      </span>
    ),
    description: `Releasing speech data in 10 different Indian Languages to encourage the members from academia and industry to build speech applications for Indian languages. The total data amounts to 2000 hours. You can find more details in the arXiv paper.`,
    imgUrl: ImageUrl.languageimg,
    table: <FirstTable />,
    gitHubLink: "",
  },
  {
    id: 3,
    header: (
      <span>
        Statistics of the
        {""} <span className="text-[#CB8461]">Data</span>{" "}
      </span>
    ),
    description: "",
    imgUrl: ImageUrl.source,
    table: <SecondTable />,
    gitHubLink: "",
  },
  {
    id: 4,
    header: (
      <span>
        Source of {""} <span className="text-[#CB8461]">DATA</span>
      </span>
    ),
    description:
      "This data was collected on payment basis using the following vendors -- Mediscribe India, Desicrew, and Crescendo. Preliminary checking of quality of transcriptions was done by our partners at KL University as well as by SPRING Lab members. The data consists mostly of mock conversations as well as monolgues on different topics.",
    imgUrl: ImageUrl.sourceRE,
    table: "",
    gitHubLink: "",
  },
  {
    id: 4,
    header: (
      <span className="w-full">
        {" "}
        <span className="text-[#CB8461]">NLTM</span>{" "}
        <span>Pilot and {""} </span>
        <span className="text-[#CB8461]">NPTEL </span>
        <span>Curated Data</span>
      </span>
    ),
    name: "no",
    description: "",
    imgUrl: "",
    table: <HoursLinkTable />,
    gitHubLink: "",
  },
  {
    id: 5,
    header: (
      <span>
        Funding {""} <span className="text-[#CB8461]"></span>
      </span>
    ),
    description:
      "This data collection effort was funded by Ministry of Electronics & Information Technology.",
    imgUrl: ImageUrl.fundingImg,
    table: "",
    gitHubLink: "",
  },

  {
    id: 6,
    header: `License`,
    description: `This is released under CCBY 4.0 license`,
    imgUrl: ImageUrl.license,
    table: "",
    gitHubLink: "",
  },
];
