import React from 'react'
import '../../Demo/Demo1/Body.css';
import { ImageUrl } from '../../../constants/url/ImagePath';
import { useState } from 'react';
import { DownloadData } from './tableBody/DownloadData';
import { AsrReceipes } from './tableBody/AsrReceipes';
import Faq from './tableBody/Faq';

const modelTabs = [
    { "name": "Download Data" },
    { "name": "ASR Recipes" },
    // { "name": "FAQ" },

]

const Body = () => {
    const [activeTab, setActiveTab] = useState("Download Data")
    const changetab = (tabName) => {

        setActiveTab(tabName)
    }
    return (
        <>

            <section className="w-full bg-[#FFF] flex flex-col gap-y-10 px-6 md:px-36 mt-12">
                <div className='w-full flex gap-x-3'>
                    {
                        modelTabs.map(item => {
                            return <div key={item.name} onClick={() => changetab(item.name)}
                                className={`px-2 py-2 text-center text-[15px] rounded-lg font-semibold w-40 not-italic ${activeTab === item.name ? "bg-[#030F30] text-white cursor-pointer" : " bg-[#ECF2FE] cursor-pointer text-[#030F30]"}`}>
                                {item.name}
                            </div>
                        })
                    }

                </div>

                {activeTab == "Download Data" ? <DownloadData /> : ""}
                {activeTab == "ASR Recipes" ? <AsrReceipes /> : ""}
                {activeTab == "FAQ" ? <Faq /> : ""}

            </section>
        </>
    )
}

export default Body