import React from "react";
import Navbar from "../Shared/Navbar";
import { ImageUrl } from "../../constants/url/ImagePath";

const EmplyeesHead = () => {
  return (
    <div>
      <div className="">
        <div className="headerContactDiv ">
          <Navbar />

          <div className="flex flex-row justify-center pt-20">
            <div className="hidden md:flex mt-9 2xl:mt-11">
              <img
                src={ImageUrl.waveImage}
                alt=""
                className="w-56 2xl:w-full float-left mt-5 "
              />
            </div>
            <div className=" font-semibold not-italic mainHeading ">
              <div className="text-center sm:text-sm-1 md:text-md-1 xl:text-xl-1">
                Employees&nbsp;
              </div>
            </div>

            <div className="hidden md:flex mt-9 2xl:mt-11">
              <img
                src={ImageUrl.waveImage}
                alt=""
                className="w-56 2xl:w-full float-right mt-5"
              />
            </div>
          </div>
          <div className="flex flex-wrap justify-center mx-auto mt-1 md:mt-5  ">
            <div className=" w-full md:w-1/2">
              {/* <p className="text-center font-light text-[14px] lg:text-[0.95rem] 2xl:text-[1.25rem] px-3 md:px-0 descriptionText ">
                Behind the scenes: A glimpse into the fun and friendship that
                fuels our work.{" "}
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmplyeesHead;
